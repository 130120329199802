import React, { useCallback, useEffect } from 'react';
import ReactFlow, { Controls, Background } from 'reactflow';
import 'reactflow/dist/style.css';
import { useCreateBlockNote, SuggestionMenuController } from '@blocknote/react';
import { filterSuggestionItems } from "@blocknote/core";
import useWorkflowStore from '../services/store/workflowStore';
import TaskBlockNode from './TaskBlockNode';
import { BlockNoteView } from '@blocknote/react';
import './WorkflowManager.css';
import getVariableMenuItems from "./variableMenu";
import customSchema from "./customschema";

const nodeTypes = {
  taskBlock: TaskBlockNode,
};

const WorkflowManager = ({ aiTaskBlockId, existingWorkflow }) => {
  const editor = useCreateBlockNote({ schema: customSchema });
  const nodes = useWorkflowStore((state) => state.nodes) || [];
  console.log("nodes", nodes);
  const setNodes = useWorkflowStore((state) => state.setNodes);
  const edges = useWorkflowStore((state) => state.edges) || [];
  console.log("edges", edges);
  const setEdges = useWorkflowStore((state) => state.setEdges);
  const selectedNode = useWorkflowStore((state) => state.selectedNode);
  console.log("selectedNode", selectedNode);
  const setSelectedNode = useWorkflowStore((state) => state.setSelectedNode);
  const getEditorContent = useWorkflowStore((state) => state.getEditorContent);
  const setEditorContent = useWorkflowStore((state) => state.setEditorContent);
  const updateAITaskBlockContent = useWorkflowStore((state) => state.updateAITaskBlockContent);

  useEffect(() => {
    if (existingWorkflow) {
      setNodes(existingWorkflow.nodes || []);
      setEdges(existingWorkflow.edges || []);
      setSelectedNode(existingWorkflow.selectedNode);
      existingWorkflow.editorContentMap && Object.entries(existingWorkflow.editorContentMap).forEach(([nodeId, content]) => {
        setEditorContent(nodeId, content);
        console.log("setting editor content for node", nodeId, content);
      });
    } else if (aiTaskBlockId) {
      const aiTaskBlock = useWorkflowStore.getState().getAITaskBlockById(aiTaskBlockId);
      console.log("aiTaskBlock", aiTaskBlock);
      if (aiTaskBlock) {
        setNodes(aiTaskBlock.nodes || []);
        setEdges(aiTaskBlock.edges || []);
        setSelectedNode(aiTaskBlock.selectedNode);
        aiTaskBlock.editorContentMap && Object.entries(aiTaskBlock.editorContentMap).forEach(([nodeId, content]) => {
          setEditorContent(nodeId, content);
          console.log("setting editor content for node", nodeId, content);
        });
      }
    } else {
      // Set default values when neither existingWorkflow nor aiTaskBlockId exists
      setNodes([
        {
          id: 'task-1',
          type: 'taskBlock',
          position: { x: 0, y: 0 },
          data: { label: 'Task 1' },
        },
      ]);
      setEdges([]);
      setSelectedNode('task-1');
      setEditorContent('task-1', '');
    }
  }, [aiTaskBlockId, existingWorkflow]);

  const handleNodeSelect = useCallback(
    async (nodeId) => {
      setSelectedNode(nodeId);
      const markdown = getEditorContent(nodeId);
      const blocks = await editor.tryParseMarkdownToBlocks(markdown);
      editor.replaceBlocks(editor.document, blocks);
    },
    [editor, getEditorContent, setSelectedNode]
  );

  const handleEditorChange = useCallback(
    async () => {
      if (selectedNode && editor) {
        const markdown = await editor.blocksToMarkdownLossy(editor.document);
        setEditorContent(selectedNode, markdown);
        updateAITaskBlockContent(existingWorkflow ? existingWorkflow.id : aiTaskBlockId, {
          nodes,
          edges,
          selectedNode,
          editorContentMap: useWorkflowStore.getState().editorContentMap,
        });
      }
    },
    [selectedNode, editor, setEditorContent, updateAITaskBlockContent, existingWorkflow, aiTaskBlockId, nodes, edges]
  );

  const handleAddTaskNode = useCallback(
    async () => {
      const newNodeId = `task-${nodes.length + 1}`;
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : { position: { x: 0, y: 0 } };
      const newNode = {
        id: newNodeId,
        type: 'taskBlock',
        position: {
          x: lastNode.position.x,
          y: lastNode.position.y + 100,
        },
        data: { label: `Task ${newNodeId}` },
      };

      const newEdge = nodes.length > 0 ? {
        id: `edge-${lastNode.id}-${newNodeId}`,
        source: lastNode.id,
        target: newNodeId,
        animated: true,
        arrowHeadType: 'arrowclosed',
      } : null;

      setNodes([...nodes, newNode]);
      if (newEdge) {
        setEdges([...edges, newEdge]);
      }
      setEditorContent(newNodeId, '');
      await handleNodeSelect(newNodeId);
      updateAITaskBlockContent(existingWorkflow ? existingWorkflow.id : aiTaskBlockId, {
        nodes: [...nodes, newNode],
        edges: newEdge ? [...edges, newEdge] : edges,
        selectedNode: newNodeId,
        editorContentMap: {
          ...useWorkflowStore.getState().editorContentMap,
          [newNodeId]: '',
        },
      });
    },
    [nodes, edges, setNodes, setEdges, setEditorContent, handleNodeSelect, updateAITaskBlockContent, existingWorkflow, aiTaskBlockId]
  );
  return (
    <div className="workflow-manager">
      <div className="workflow-manager-flow">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodeClick={(event, node) => {
            event.stopPropagation();
            handleNodeSelect(node.id);
          }}
          nodeTypes={{
            taskBlock: (props) => (
              <TaskBlockNode {...props} updateNodeLabel={useWorkflowStore.getState().updateNodeLabel} />
            ),
          }}
          defaultViewport={{ x: 25, y: 0, zoom: 1.5 }}
          minZoom={0.2}
          maxZoom={4}
        >
          <Controls />
          <Background />
        </ReactFlow>
        <button className="add-task-button" onClick={handleAddTaskNode}>
          Add Task Node
        </button>
      </div>
      <div className="workflow-manager-editor">
        <BlockNoteView editor={editor} onChange={handleEditorChange}>
          <SuggestionMenuController
            triggerCharacter={"@"}
            getItems={async (query) => filterSuggestionItems(getVariableMenuItems(editor), query)}
          />
        </BlockNoteView>
      </div>
    </div>
  );
};

export default WorkflowManager;