import { createReactInlineContentSpec } from "@blocknote/react";

export const VariableInlineContent = createReactInlineContentSpec(
  {
    type: "variable",
    propSchema: {
      variableType: {
        default: "firstName",
        values: [
          "firstName",
          "lastName",
          "jobTitle",
          "companyName",
          "companyDomain",
          "contactLinkedin",
          "companyLinkedin",
          "email",
        ],
      },
    },
    content: "none",
  },
  {
    render: (props) => (
      <span style={{ backgroundColor: "#8400ff33" }}>
        @{props.inlineContent.props.variableType}
      </span>
    ),
  }
);