import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../services/auth/supabaseClient';
import '../App.css'; // Make sure this is imported if not already

export default function Login() {
  const navigate = useNavigate();

  const handleLoginSuccess = () => {
    navigate('/protected/editor');
  };

  return (
    <div className="login-container">
      <div className="auth-ui">
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={['google']}
          onlyThirdPartyProviders
          onSuccess={handleLoginSuccess}
        />
      </div>
    </div>
  );
}