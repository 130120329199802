import { BlockNoteSchema, defaultBlockSpecs, defaultInlineContentSpecs } from "@blocknote/core";
import AITaskBlock from "./AITaskBlock";
import { VariableInlineContent } from "./VariableInlineContent";

const customSchema = BlockNoteSchema.create({
  blockSpecs: {
    ...defaultBlockSpecs,
    "ai-task-block": {
      ...AITaskBlock,
      propSchema: {
        aiTaskBlockId: { default: '' },
        aiTaskBlockName: { default: '' },
        aiTaskBlockContent: { default: '' },
        nodeCount: { default: 0 },
      },
    },
  },
  inlineContentSpecs: {
    ...defaultInlineContentSpecs,
    variable: VariableInlineContent,
  },
});

export default customSchema;