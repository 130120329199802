import { createReactBlockSpec } from '@blocknote/react';
import AITaskBlockComponent from './AITaskBlockComponent';

const AITaskBlock = createReactBlockSpec(
  {
    type: 'ai-task-block',
    propSchema: {},
    content: 'none',
  },
  {
    render: (props) => {
      return <AITaskBlockComponent blockId={props.block.id} />;
    },
  }
);

export default AITaskBlock;