import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
     
function VerticalNavbar() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleProfileClick = () => {
    navigate('/protected/profile');
  };

  const handleDashboardClick = () => {
    navigate('/protected/dashboard');
  };

  const handleEditorClick = () => {
    navigate('/protected/editor');
  };

  return (
    <nav className="vertical-navbar">
      <div className="navbar-logo">
        <img src="/Digital Leverage Logo SVG black.svg" alt="Logo" />
      </div>
      <button className="navbar-button" onClick={handleLoginClick}>
        Login
      </button>
      <button className="navbar-button" onClick={handleProfileClick}>
        Profile
      </button>
      <button className="navbar-button" onClick={handleDashboardClick}>
        Dashboard
      </button>
      <button className="navbar-button" onClick={handleEditorClick}>
        Editor
      </button>
    </nav>
  );
}

export default VerticalNavbar;

