import React from 'react';

function EditorFooter() {
  return (
    <div className="editor-footer">
      <button className="editor-footer-button">Create Document</button>
      <button className="editor-footer-button">Save to Dashboard</button>
    </div>
  );
}

export default EditorFooter;