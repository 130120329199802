import React from 'react';
import "@blocknote/core/fonts/inter.css";
import { BlockNoteView, useCreateBlockNote, SuggestionMenuController } from "@blocknote/react";
import { filterSuggestionItems } from "@blocknote/core";
import "@blocknote/react/style.css";
import customSchema from "../components/customschema";
import getCustomSlashMenuItems from "../components/customSlashMenu";
import getVariableMenuItems from "../components/variableMenu";
import VerticalNavbar from '../components/VerticalNavbar';
import EditorFooter from '../components/EditorFooter';


function App() {
  const editor = useCreateBlockNote({
    schema: customSchema,
    // ...
  });


  return (
    <div style={{ display: 'flex', minHeight: '100vh', backgroundColor: '#ffff' }}>
      <div style={{ width: '13%' }}>
        <VerticalNavbar />
      </div>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', padding: '20px', paddingTop: '100px' }}>
        <div style={{ width: '65%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: 1, minHeight: '85vh', overflowY: 'auto' }}>
          <BlockNoteView editor={editor} slashMenu={false} className="bn-container">
              <SuggestionMenuController
                triggerCharacter={"/"}
                getItems={async (query) => filterSuggestionItems(getCustomSlashMenuItems(editor), query)}
              />
              <SuggestionMenuController
                triggerCharacter={"@"}
                getItems={async (query) => filterSuggestionItems(getVariableMenuItems(editor), query)}
              />
            </BlockNoteView>
          </div>
          <EditorFooter />
        </div>
      </div>
    </div>
  );
}

export default App;