const getVariableMenuItems = (editor) => {
    const variableMenuItems = [
      {
        title: "First Name",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "firstName",
              },
            },
            " ",
          ]);
        },
        aliases: ["firstname"],
      },
      {
        title: "Last Name",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "lastName",
              },
            },
            " ",
          ]);
        },
        aliases: ["lastname"],
      },
      {
        title: "Job Title",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "jobTitle",
              },
            },
            " ",
          ]);
        },
        aliases: ["jobtitle"],
      },
      {
        title: "Company Name",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "companyName",
              },
            },
            " ",
          ]);
        },
        aliases: ["companyname"],
      },
      {
        title: "Company Domain",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "companyDomain",
              },
            },
            " ",
          ]);
        },
        aliases: ["companydomain"],
      },
      {
        title: "Company Website",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "companyWebsite",
              },
            },
            " ",
          ]);
        },
        aliases: ["companywebsite", "website"],
      },
      {
        title: "Company Home Page",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "companyHomePage",
              },
            },
            " ",
          ]);
        },
        aliases: ["companyhomepage", "homepage"],
      },
      {
        title: "Company About Us Page",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "companyAboutUs",
              },
            },
            " ",
          ]);
        },
        aliases: ["companyaboutus", "aboutus"],
      },
      {
        title: "Company Products Page",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "companyProducts",
              },
            },
            " ",
          ]);
        },
        aliases: ["companyproducts", "products"],
      },
      {
        title: "Company Pricing Page",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "companyPricing",
              },
            },
            " ",
          ]);
        },
        aliases: ["companypricing", "pricing"],
      },
      {
        title: "Contact LinkedIn",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "contactLinkedin",
              },
            },
            " ",
          ]);
        },
        aliases: ["contactlinkedin"],
      },
      {
        title: "Company LinkedIn",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "companyLinkedin",
              },
            },
            " ",
          ]);
        },
        aliases: ["companylinkedin"],
      },
      {
        title: "Email",
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: "variable",
              props: {
                variableType: "email",
              },
            },
            " ",
          ]);
        },
        aliases: ["email"],
      },
    ];
  
    return variableMenuItems;
  };
  
  export default getVariableMenuItems;