import Input from "../../components/UI/input";
import Button from "../../components/UI/button";
import '../../App.css';
import { useState, useEffect } from 'react';
import { flushSync } from 'react-dom';

function formatTaskName(taskName) {
  return taskName
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ') + ':';
}

function CopyIcon({ textToCopy }) {
  const [clicked, setClicked] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      console.log('Text copied to clipboard');
      setClicked(true);
      setTimeout(() => setClicked(false), 300); 
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <svg
      onClick={handleCopy}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        padding: '15px',
        cursor: 'pointer',
        backgroundColor: clicked ? '#d3d3d3' : 'transparent', // Change background color on click
        borderRadius: '4px' // Optional: Add border radius for better visual
      }}
    >
      <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
      <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
    </svg>
  );
}

const fetchData = async (endpoint, requestBody) => {
  try {
    console.log('Submitting form with request body:', requestBody);
    const response = await fetch(`http://localhost:8000/${endpoint}?streaming=true`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }

    const results = await response.json();
    return results;
  } catch (error) {
    console.error('Failed to fetch data:', error);
    throw error;
  }
};

export function ICPGeneration() {
  const [output, setOutput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState('icp');
  const [taskVisibility, setTaskVisibility] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const domainList = e.target.elements.domain.value;
    const domains = domainList.split(',').map(domain => domain.trim());

    let requestBody;
    let endpoint;

    if (selectedFeature === 'automatic-competitor-analysis') {
      requestBody = { domain: domains[0] };
      endpoint = 'competitor-analysis/auto';
    } else if (selectedFeature === 'competitor-analysis') {
      requestBody = { domains };
      endpoint = 'competitor-analysis/manual';
    } else {
      requestBody = { domain: domains[0] };
      endpoint = selectedFeature;
    }

    try {
      const results = await fetchData(endpoint, requestBody);
      setOutput(results);
      setTaskVisibility(results.reduce((acc, result) => ({
        ...acc,
        [result.task_name]: true,
      }), {}));
    } catch (error) {
      setOutput([{ error: error.message }]);
    } finally {
      setLoading(false);
      console.log('Form submission completed.');
    }
  };

  const toggleTaskVisibility = (taskName) => {
    console.log('Toggling visibility for task:', taskName);
    setTaskVisibility((prevState) => ({
      ...prevState,
      [taskName]: !prevState[taskName],
    }));
  };

  return (
    <div className="icp-generation-container">
      <h1>Competitor Context API</h1>
      <form className="icp-generation-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <Input
            placeholder="Enter domains (comma-separated)"
            type="text"
            name="domain"
            className="icp-generation-input"
          />
          <Button type="submit" className="icp-generation-button">
            Generate
          </Button>
        </div>
        <div className="feature-selection">
          <label>
            <input
              type="radio"
              value="find-competitors"
              checked={selectedFeature === 'find-competitors'}
              onChange={() => setSelectedFeature('find-competitors')}
            />
            Find Competitors
          </label>
          <label>
            <input
              type="radio"
              value="automatic-competitor-analysis"
              checked={selectedFeature === 'automatic-competitor-analysis'}
              onChange={() => setSelectedFeature('automatic-competitor-analysis')}
            />
            Automatic Competitor Analysis
          </label>
          <label>
            <input
              type="radio"
              value="competitor-analysis"
              checked={selectedFeature === 'competitor-analysis'}
              onChange={() => setSelectedFeature('competitor-analysis')}
            />
            Competitor Analysis
          </label>
        </div>
      </form>
      <div className="output-container">
        {loading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : (
          output.map((result, index) => (
            <div key={index} className="task-component">
              <div className="task-header">
                <h3>{formatTaskName(result.task_name)}</h3>
                <button onClick={() => toggleTaskVisibility(result.task_name)}>
                  {taskVisibility[result.task_name] ? 'Hide' : 'Show'}
                </button>
              </div>
              {taskVisibility[result.task_name] && (
                <div className="pre-container">
                  <pre>
                    {JSON.stringify(result.output_data, null, 2)
                      .replace(/\\n/g, '\n')
                      .replace(/^"(.*)"$/, '$1')}
                  </pre>
                  <CopyIcon textToCopy={JSON.stringify(result.output_data, null, 2)} />
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
}
