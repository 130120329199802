import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';

export default function Homepage() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleEditorClick = () => {
    navigate('/protected/editor');
  };

  return (
    <div className="homepage">
      <h1>Welcome to Personalized Lead Magnet App!</h1>
      <div className="button-container">
        <button className="button" onClick={handleLoginClick}>Login</button>
        <button className="button" onClick={handleEditorClick}>Editor</button>
      </div>
    </div>
  );
}