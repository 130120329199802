import React, { useState, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import useWorkflowStore from '../services/store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import { FaPencilAlt } from 'react-icons/fa';
import './TaskBlockNode.css';

const MAX_LABEL_LENGTH = 20;

const TaskBlockNode = ({ data, id, selected }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(data.label);
  const updateNodeLabel = useWorkflowStore((state) => state.updateNodeLabel);
  const editorContentMap = useWorkflowStore(useShallow((state) => state.editorContentMap));

  const editorContent = editorContentMap[id] || '';
  const firstSnippet = editorContent.split('\n')[0] || '';

  const handleLabelChange = useCallback((event) => {
    setInputValue(event.target.value);
  }, []);

  const handleLabelBlur = useCallback(() => {
    updateNodeLabel(id, inputValue);
    setIsEditing(false);
  }, [id, inputValue, updateNodeLabel]);

  const handleLabelKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      updateNodeLabel(id, inputValue);
      setIsEditing(false);
    }
  }, [id, inputValue, updateNodeLabel]);

  const truncateLabel = (label) => {
    if (label.length > MAX_LABEL_LENGTH) {
      return label.slice(0, MAX_LABEL_LENGTH) + '...';
    }
    return label;
  };

  return (
    <div className={`task-block-node ${selected ? 'selected' : ''}`}>
      <Handle type="target" position={Position.Top} />
      <div className="task-block-content">
        {isEditing ? (
          <input
            type="text"
            value={inputValue}
            onChange={handleLabelChange}
            onBlur={handleLabelBlur}
            onKeyPress={handleLabelKeyPress}
            placeholder={inputValue}
            autoFocus
          />
        ) : (
          <>
            <div className="task-block-label">
              {truncateLabel(data.label)}
              <FaPencilAlt
                className="edit-icon"
                onClick={() => setIsEditing(true)}
              />
            </div>
            <div className="task-block-snippet">{firstSnippet}</div>
          </>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default TaskBlockNode;