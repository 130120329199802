import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './useAuth';

const PrivateRoute = () => {
  console.log('PrivateRoute: rendering');
  const { user, loading } = useAuth();

  console.log('PrivateRoute: user', user);
  console.log('PrivateRoute: loading', loading);

  if (loading) {
    console.log('PrivateRoute: loading');
    return <div>Loading...</div>;
  }

  if (!user) {
    console.log('PrivateRoute: navigating to login');
    return <Navigate to="/login" replace />;
  }

  console.log('PrivateRoute: rendering Outlet');
  return <Outlet />;
};

export default PrivateRoute;