import { getDefaultReactSlashMenuItems } from "@blocknote/react";
import { AiOutlineRobot } from "react-icons/ai";
import { createRoot } from 'react-dom/client';
import AITaskPopupManager from './AITaskPopupManager';
import useWorkflowStore from '../services/store/workflowStore';

const getCustomSlashMenuItems = (editor) => {
  console.log("Entering getCustomSlashMenuItems");

  const menuItems = [
    ...getDefaultReactSlashMenuItems(editor),
    {
      title: "AI Task Block",
      onItemClick: () => {
        console.log("AI Task Block menu item clicked");

        const currentBlock = editor.getTextCursorPosition().block;
        const blockId = editor.insertBlocks(
          [{ type: "ai-task-block", props: {} }],
          currentBlock,
          "after"
        )[0];

        console.log("Created AITaskBlock with Block ID:", blockId);

        const aiTaskBlockId = useWorkflowStore.getState().createAITaskBlock(blockId.id, '', '');

        const popupContainer = document.getElementById('popup-container');
        const popupRoot = createRoot(popupContainer);
        console.log("Rendering AITaskPopupManager");
        popupRoot.render(
          <AITaskPopupManager
            onClose={() => popupRoot.unmount()}
            aiTaskBlockId={aiTaskBlockId}
          />
        );
        console.log("Rendered AITaskPopupManager");
      },
      aliases: ["ai", "task"],
      group: "Other",
      icon: <AiOutlineRobot size={18} />,
      subtext: "Insert an AI task block",
    },
  ];

  console.log("Returning menu items:", menuItems);
  return menuItems;
};

export default getCustomSlashMenuItems;