import React from 'react';
import { createRoot } from 'react-dom/client';
import useWorkflowStore from '../services/store/workflowStore';
import AITaskPopupManager from './AITaskPopupManager';

const AITaskBlockComponent = ({ blockId }) => {
  const aiTaskBlock = useWorkflowStore((state) => state.getAITaskBlockByBlockId(blockId)) || {};
  console.log(" [AITaskBlockComponent] Rendered aiTaskBlock", aiTaskBlock);

  const handleNameChange = (event) => {
    const newName = event.target.value;
    useWorkflowStore.getState().updateAITaskBlockName(aiTaskBlock.id, newName);
    console.log("[AITaskBlockComponent] Updated aiTaskBlock", aiTaskBlock);
  };

  const nodeCount = aiTaskBlock.nodes ? aiTaskBlock.nodes.length : 0;
  const name = aiTaskBlock.name || '';

  const onOpenWorkflow = () => {
    const popupContainer = document.getElementById('popup-container');
    const popupRoot = createRoot(popupContainer);
    const onClose = () => {
      popupRoot.unmount();
    };
    popupRoot.render(
      <AITaskPopupManager
        blockId={blockId}
        onClose={onClose}
      />
    );
  };

  return (
    <div onClick={onOpenWorkflow}>
      <h3>AI Task Block</h3>
      <p>Node Count: {nodeCount}</p>
      <input
        type="text"
        value={name}
        onChange={handleNameChange}
        placeholder="Enter a name"
      />
    </div>
  );
};

export default AITaskBlockComponent;