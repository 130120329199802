import React from 'react';
import WorkflowManager from './WorkflowManager';
import useWorkflowStore from '../services/store/workflowStore';

const AITaskPopup = ({ onClose, onSaveWorkflow, aiTaskBlockId }) => {
  console.log(`[AITaskPopup] Rendering with aiTaskBlockId: ${aiTaskBlockId}`);
  const aiTaskBlock = useWorkflowStore((state) => state.getAITaskBlockById(aiTaskBlockId));
  console.log(`[AITaskPopup] Retrieved aiTaskBlock: ${JSON.stringify(aiTaskBlock)}`);

  const handleSaveWorkflow = (updatedData) => {
    console.log(`[AITaskPopup] handleSaveWorkflow called with updatedData: ${JSON.stringify(updatedData)}`);
    onSaveWorkflow({ ...updatedData, id: aiTaskBlock.id, blockId: aiTaskBlock.blockId });
  };

  return (
    <div className="ai-task-popup">
      <div className="ai-task-popup-content">
        <h2>Edit AI Task Workflow</h2>
        <div className="ai-task-popup-workflow">
          <WorkflowManager aiTaskBlock={aiTaskBlock} onSave={handleSaveWorkflow} />
        </div>
        <div className="ai-task-popup-actions">
          <button onClick={() => {
            console.log('[AITaskPopup] Save Workflow button clicked');
            handleSaveWorkflow(aiTaskBlock);
          }}>Save Workflow</button>
          <button onClick={() => {
            console.log('[AITaskPopup] Cancel button clicked');
            onClose();
          }}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default AITaskPopup;

