import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';

const useWorkflowStore = create((set, get) => ({
  aiTaskBlocks: {},
  blockIdMapping: {},

  // manage state between react flow nodes and blocknote editor inside workflow 
  setNodes: (nodes) => {
    set((state) => {
      const updatedContentMap = { ...state.editorContentMap };
      nodes.forEach((node) => {
        if (!updatedContentMap[node.id]) {
          updatedContentMap[node.id] = '';
        }
      });
      return { nodes, editorContentMap: updatedContentMap };
    });
  },

  setEdges: (edges) => set({ edges }),

  getEditorContent: (nodeId) => {
    return useWorkflowStore.getState().editorContentMap[nodeId] || '';
  },

  setEditorContent: (nodeId, content) => {
    set((state) => ({
      editorContentMap: {
        ...state.editorContentMap,
        [nodeId]: content,
      },
    }));
  },

  setSelectedNode: (nodeId) => set({ selectedNode: nodeId }),
  
  updateNodeLabel: (nodeId, newLabel) =>
    set((state) => {
      const updatedNodes = state.nodes.map((node) =>
        node.id === nodeId ? { ...node, data: { ...node.data, label: newLabel } } : node
      );
      return { nodes: updatedNodes };
    }),

  // manage state of AI Task Blocks and their content inside the primary editor in editor.js   

  createAITaskBlock: (blockId, name, content) => {
    const id = uuidv4();
    console.log(`Creating AI Task Block with ID: ${id}, Block ID: ${blockId}, Name: ${name}`);
    set((state) => ({
      aiTaskBlocks: {
        ...state.aiTaskBlocks,
        [id]: {
          id,
          blockId,
          name,
          content,
          nodes: [
            {
              id: 'task-1',
              type: 'taskBlock',
              position: { x: 0, y: 0 },
              data: { label: 'Task 1' },
            },
          ],
          edges: [],
          selectedNode: 'task-1',
          editorContentMap: { 'task-1': '' },
        },
      },
      blockIdMapping: {
        ...state.blockIdMapping,
        [blockId]: id  // Update the mapping here
      },
    }));
    return id;
  },

  getAITaskBlockByBlockId: (blockId) => {
    const state = get();
    const aiTaskBlockId = state.blockIdMapping[blockId];
    console.log(`Retrieving AI Task Block with Block ID: ${blockId}, AI Task Block ID: ${aiTaskBlockId}`);
    return state.aiTaskBlocks[aiTaskBlockId];
  },
  
  updateAITaskBlock: (id, updatedData) => {
    console.log(`Updating AI Task Block with ID: ${id}, Updated Data: ${JSON.stringify(updatedData)}`);
    set((state) => ({
      aiTaskBlocks: {
        ...state.aiTaskBlocks,
        [id]: {
          ...state.aiTaskBlocks[id],
          ...updatedData,
        },
      },
    }));
  },

  getAITaskBlockById: (id) => {
    const block = useWorkflowStore.getState().aiTaskBlocks[id];
    console.log(`Retrieving AI Task Block with ID: ${id}, Retrieved Data: ${JSON.stringify(block)}`);
    return block;
  },

  updateAITaskBlockName: (id, name) => {
    set((state) => ({
      aiTaskBlocks: {
        ...state.aiTaskBlocks,
        [id]: {
          ...state.aiTaskBlocks[id],
          name,
        },
      },
    }));
  },

  updateAITaskBlockContent: (id, data) => {
    set((state) => ({
      aiTaskBlocks: {
        ...state.aiTaskBlocks,
        [id]: {
          ...state.aiTaskBlocks[id],
          ...data,
        },

      },
    }));
  },
}));

export default useWorkflowStore;
