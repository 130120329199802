// routes.js
import { createBrowserRouter } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Login from './pages/Login';
import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
import Editor from './pages/editor';
import PrivateRoute from './services/auth/PrivateRoute';
import { ICPGeneration } from './pages/icp_generation/icp_generation';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/icp-generation',
    element: <ICPGeneration />,
  },
  {
    path: '/protected',
    element: <PrivateRoute />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'editor',
        element: <Editor />,
      },
    ],
  },
]);